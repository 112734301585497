.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root{
    border: 1px solid #F0E3F1 !important;
}


.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root:hover {
    background-color: #805382 !important;
    color:white;
}


.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color: #5D3B5E !important;
    color:white;

}

.page-head{
    margin-left:60px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media only screen and (max-width: 1500px) {
    .page-head {
       margin-left:50px;
       padding: 7px;
    }
}
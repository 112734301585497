 .report_test-row{
    position:fixed;
    background-color:#e9f5e4;
    height: 70px;
    top:76px;
    width:100%;
    align-items: center;
}

.reportList-table{
 margin-top: 146px;
} 

.reportList-table tbody td,
.reportList-table thead th{
    vertical-align: middle !important;
}

.TC-bulk-btn-TSR{
  border-color: none;
  border-style: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  background: #7eb965;
  margin-top: 18px;
  max-width: 150px;
  min-width: 150px;
  align-content: center;
  height: 30px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  padding-inline: 15px;
}
.CSH-Canvas-edit-head {
    margin-top: 11.95rem;
    width: 40% !important;
    background: #f0e3f1;
}

.CSH-edit-label,
.CSH-DP-head {
    margin-left: 20px;
    font-weight: bold;
}

.CSH-browser-label {
    font-weight: bold;
}

.ts-form,
.select-edit-CSH-env,
.CSH-suite-buttons {
    margin-left: 20px;
    width: 95% !important;
}

.CSH-browser-version {
    margin-left: 30px;
}

.CSH-version-form{
    width: 18rem;
    height: 38px;
}

.CSH-edit-version-select {
    width:96% !important;
    height: 38px;
    border: 1px solid #9f9f9f;
    border-radius: 5px;
}


.FR-edit-sec .CSH-time{
    height: 38px;
    width: 180px;
    border: 1px solid #9f9f9f;
    border-radius: 5px;
    margin-top: 32px;
    margin-left: 20px;
    padding-inline: 10px;
}

.CSH-edit-day-version-select{
    width: 200px !important;
    height: 38px;
    border: 1px solid #9f9f9f;
    border-radius: 5px;
    margin-top: 32px;
    margin-left: 4px;
    font-size: 15px;
}

.CSH-edit-day-version-select select{
  appearance: none;
}
/* .testsuite-slider {
  background-color: #f0e3f1;
  margin-top: 184px;
  width: 100%;
} */
/* .edit-testsuite {
  background-color: #f0e3f1;
  margin-top: 184px;
  margin-left: 40px;
  width: 50em;
  height: 100%;
  height: 896px;
} */

.CSH-browser-select {
    display: flex;
    /* gap: 40px; */
}

.CSH-browser-icon {
    margin-left: 20px;
    gap: 10px;
}

.CSH-suite-buttons {
    display: flex;
    margin-top: 30px;
    gap: 20px;
}

.save-CSH {
    width: 128px;
    height: 40px;
    border: none;
    background-color: #7eb965;
    border-radius: 5px;
    color: white;
}

.CSH-cancel-ts {
    width: 128px;
    height: 40px;
    border: none;
    background-color: #9f9f9f;
    border-radius: 5px;
    color: white;
}



.text-area:hover {
    height: 100px;
}

.CSH-edit {
    text-align: center;
    color: #805382;
    font-size: 16px;
    font: normal normal bold 16px/19px Montserrat;
}

.CSH-DP-input input {
    width: 250px;
    height: 38px;
    border: 1px solid grey;
    border-radius: 5px;
}

.CSH-FR-head{
  margin-left: 0px !important;
    height: 38px;
    border-radius: 5px; 
     width: 100% !important;
  }

/* .CSH-edit-Date {
    gap: 10px;
} */

.CSH-list {

    border-radius: 15px;
    border-collapse: collapse;
}

.CSH-list tbody {
    background: #f8f8f8;
}

.CSH-list thead th {
    font-size: 13px;
}


/*responsive queries*/

@media all and (max-width:1500px) {
 .CSH-Canvas-edit-head {
    width:35% !important;
  }

  .CSH-DP-input input{
    width: 150px !important;
    height: 33px !important;
    border: 1px solid grey;
  }

  .CSH-version-select{
    height: 33px !important;
    border: 1px solid grey;
  }

  .CSH-edit-day-version-select{
    height: 33px !important;
    width: 180px !important;
    border: 1px solid grey;
    margin-left: 7px !important;
  }
  
  .CSH-Canvas-edit-head Label, .select-ts-env option {
    font-size: 13.5px !important;
  }

  .CSH-FR-head input{
    height: 30px !important;
    border: 1px solid grey;
    margin-top: 30px;
  }
  
}

@media screen and (max-width:1500px) {
  .CSH-Canvas-edit-head select {
    height: 33px;
    font-size: 13px;
  }

  .FR-edit-sec .CSH-time{
    height: 33px !important;
    font-size: 13px !important;
    border: 1px solid rgb(193, 193, 193);
}
}

@media screen and (max-width:1500px) {
  .CSH-Canvas-edit-head .CSH-form {
    height: 33px !important;
    font-size: 13px !important;
    border: 1px solid rgb(193, 193, 193);
  }

  .CSH-suite-buttons button {
    height: 30px !important;
    width: 110px !important;
    font-size: 13px !important;
  }
}
thead {
  text-align: center;
  background-color: #f9f1f9;
  color: #805382;
  font: normal normal normal 12px/15px Montserrat;
  font-weight: bold;
}

tbody {
  text-align: center;
  width: 100%;
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

@media only screen and (max-width: 1500px) {
  tbody td {
    font-size: 11px;
  }

  thead th {
    font-size: 12.5px;
  }
}

.ad-table_content {
  max-height: 298px;
  width: 100%;
  margin-right: 75px;
  margin-top: 15px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ad-table-upcomingtest {
  border: 1px solid rgb(221, 216, 216) !important;
}

.ad-table-upcomingtest td{
  vertical-align: middle !important;
}

.ad-table-upcomingtest th{
  vertical-align: middle !important;
}
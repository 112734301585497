.Canvas-delete-ts-head {
  background-color: #eeeeee;
  margin-top: 12.2rem;
  width: 33% !important;
}

.warn {
  width: 100px;
  height: 90px;
}

.del-ts-warn {
  width: 100px;
  align-items: center;
}

.del-buton {
  border: none;
  background-color: #dc3545;
  border-radius: 5px;
  width: 128px;
  height: 40px;
}

.del-buton:hover{
  background-color: #C82333;
}

.can-buton {
  border: none;
  background-color: #9f9f9f;
  border-radius: 5px;
  width: 128px;
  height: 40px;
}

.can-button:hover{
  background-color: #5c5b5b;
}

.d-button {
  display: flex;
  gap: 14px;
  margin-top: 24px;
}

  .del-ts-button{
    gap: 10px;
  }

/*Responsive Queries*/

@media only screen and (max-width:1500px) {
  .Canvas-delete-ts-head .delete-head {
    font-size: 14px !important;
  }

  h1 {
    font-size: 35px !important;
  }

 .del-ts-button button {
    height: 30px !important;
    width: 110px !important;
  }
}
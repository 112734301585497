.create-project-canvas {
  background-color: #f0e3f1;
  margin-top: 11.8rem !important;
  width: 40% !important;
  margin-left: 40px; 
  position: fixed;
}
.error {
  color: red;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.3rem;
  display: flex;
  justify-content: flex-start;
  margin-left: 3rem;
}
@media all and (max-width:1500px){
  .create-project-canvas{
    width:35% !important;
  }
  .project-button {
    margin-right: 35px !important;
  }

  .create-pj-button{
    width: 100px !important;
    font: normal normal normal 13px/22px Montserrat !important;
  }

  .cancel-pj-button{
    width: 100px !important;
    font: normal normal normal 13px/22px Montserrat !important;
  }

  .create-pj-label{
    font-size: 13px !important;
  }
  .pj-story-box {
    height: 33px !important;
    font-size: 13px !important;
  }

  /* .create-pj-label{
    margin-left: 30px !important;
  } */

  .create-pj{
    font: normal normal bold 15px/19px Montserrat !important;
  }
}


.project-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
  margin-top: 20px;
  gap: 10px;
}

.create-pj-button {
  background-color: #7eb965;
  width: 120px;
  font: normal normal normal 15px/22px Montserrat;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
}

.create-pj-button:hover {
  background-color: #5d874c;
}
.pj-story-box {
  border: 1px solid #9f9f9f;
  height: 38px;
  border-radius: 5px;
  width: 85%; 
  margin-left: 40px;
  font-size: 14px;
  /* padding: 0.375rem 0.75rem !important; */
}
.cancel-pj-button {
  width: 120px;
  background-color: #999999;
  font: normal normal normal 15px/22px Montserrat;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
}

.cancel-pj-button:hover {
  background-color: #5c5b5b;
}
.create-pj {
  width: 100%;
  color: #805382;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 16px;
  font: normal normal bold 16px/19px Montserrat;
  opacity: 1;
}

.create-project-btn{
  width: 150px;
  height: 30px;
  margin-right: 15px;
  background-color: #7eb965;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border: none;
  border-radius: 5px;
  font-weight: normal;
  gap: 5px;
}

.create-project-btn:hover{
  background-color: #5d874c;

}


.create-pj-label{
  margin-left: 40px; 
  font-weight: bold;
  
}


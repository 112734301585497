.edit-sh-canvas{
  background-color: #f0e3f1; 
  margin-top: 11.8rem !important;
  width: 40% !important;
  margin-left: 40px;
  position: fixed;
  overflow-y: scroll;
overflow-x: hidden;
min-height: 40vh !important;
}

@media only screen and (max-width: 1500px){
     .edit-sh-canvas{
      width: 35% !important;
     }
      .edit-stake-button {
        margin-right: 30px !important;
      }

      .ed-create-sh-button{
        width: 100px !important;
        font: normal normal normal 13px/22px Montserrat !important;
      }

      .ed-cancel-sh-button{
        width: 100px !important;
        font: normal normal normal 13px/22px Montserrat !important; 
      }

    .ed-sh-story-box{
       height: 33px !important;
    font-size: 13px !important;
    }
    .edit-sh-label{
     font-size: 13px;
    } 
    .edit-sh{
      font: normal normal bold 15px/19px Montserrat !important;
    }
}

.ed-sh-story-box {
  border: 1px solid #9f9f9f;
  border-radius: 5px;
  width: 85%; 
  margin-left: 40px;
  font-size: 14px;
}



.ed-create-sh-button {
  background-color: #7eb965;
  width: 120px;
  font: normal normal normal 15px/22px Montserrat;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  }
  
  .ed-create-sh-button:hover {
    background-color: #5d874c;
  }


.ed-cancel-sh-button {
  width: 120px;
  background-color: #999999;
  font: normal normal normal 15px/22px Montserrat;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
}

.ed-cancel-sh-button:hover {
    background-color: #5c5b5b;
}

.edit-sh{
    width: 100%;
  color: #805382;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 16px;
  font: normal normal bold 16px/19px Montserrat;
  opacity: 1;
}

.edit-stake-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
  margin-top: 20px;
  gap: 10px;
  margin-bottom: 10px !important;
}

.sh-create-btn::after {
    content: none;
}


.edit-sh-label{
    margin-left: 40px; 
    font-weight: bold;
   
  }
.del-app-canvas  {
  background: #EEEEEE 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 11.8rem !important;
  margin-left: 40px;
  width: 33% !important;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 1600px){
    .app-del-warn{
      width: 80px !important;
      height: 70px !important;
      margin-bottom: 10px;
    }
    .del-app-button {
      width: 100px !important;
    font: normal normal normal 13px/22px Montserrat !important;
    }
    .cancel-delapp-button{
      width: 100px !important;
      font: normal normal normal 13px/22px Montserrat !important;
    }
  }
  .app-del-warn {
    width: 100px;
    height: 90px;
    margin-bottom: 10px;
  }
  .del-app-button {
    background-color: #dc3545;
    width: 120px;
  font: normal normal normal 15px/22px Montserrat;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  }
  .cancel-delapp-button{
    width: 120px;
    background-color: #999999;
    font: normal normal normal 15px/22px Montserrat;
    color: white;
    padding: 5px;
    border: none;
    border-radius: 5px;
  }

  .cancel-delapp-button:hover {
    background-color: #5c5b5b;
  }

  .delete-app-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
  }
.overview {
  display: flex;
  flex-direction: column;
}

.dash-head {
  width: 100%;
  margin-top: 72px;
}

/* .dashboard {
  overflow-y: hidden;
  overflow-x: hidden;
} */
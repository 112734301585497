.run-tc {
  background-color: #ecf5e8;
  margin-top: 12.2rem;
  width: 40% !important;
}

.run-title {
  text-align: center;
  margin-top: 40px;
  color: #5d874c;
  font: normal normal bold 16px/19px Montserrat;
}

.run-label {
  margin-left: 40px;
  font-weight: bold;
}

.run-form {
  margin-left: 40px;
  border-radius: 5px;
  height: 38px;
  border: 1px solid #9f9f9f;
  width: 85% !important;
}

/* .test-run {
  color: #333333;
  font: normal normal medium 14px/18px Montserrat;
  margin-left: 40px;
  margin-top: 40px;
} */

@media all and (max-width:1500px) {
  .run-tc Label {
    font-size: 13.5px !important;
  }

.run-tc{
  width: 35% !important;
}
   
}

@media screen and (max-width:1500px) {
  .run-tc select {
    height: 33px;
  }
}

@media screen and (max-width:1500px) {
  .run-tc .run-form {
    height: 33px !important;
    font-size: 13px !important;
  }

  .run-test button {
    height: 30px !important;
    width: 110px !important;
  }

  .run-button img {
    width: 18px;
    align-items: center;
    margin-bottom: 2px;
  }
}

#environment {
  width: 442px;
  margin-left: 40px;
  height: 38px;
  border: 1px #9f9f9f solid;
  border-radius: 5px;
}

.browser-icons {
  display: flex;
  margin-left: 40px;
  gap: 40px;
}

.run-version-select {
  width: 85% !important;
  height: 38px;
  margin-left: 40px;
  border: 1px solid #9f9f9f;
  border-radius: 5px;
}

#browser-version {
  width: 442px;
  margin-left: 40px;
  height: 38px;
  border: 1px #9f9f9f solid;
  border-radius: 5px;
}

.run-button {
  display: flex;
  gap: 5px;
  color: white;
  align-items: center;
  justify-content: center;
  background-color: #7eb965;
  border: none;
  border-radius: 5px;
  width: 128px;
  height: 40px;
}

.run-button:hover{
  background: #5d874c;
}

.can-button:hover{
  background: #5c5b5b;
}

/* #environment :hover {
    display: block;
  } */
.run-test {
  width: 93% !important;
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
  gap: 20px;
}



.can-button {
  background-color: #9f9f9f;
  border: none;
  color: white;
  border-radius: 5px;
  width: 128px;
  height: 40px;
}
.report_test-row{
    position:fixed;
    background-color:#e9f5e4;
    height: 64px;
    top:76px;
    width:100%;
    align-items: center;
}

.reportList-table{
    margin-top:140px; 
}
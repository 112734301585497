* {
  font-family: Montserrat;
}

/* .nav-bar {
  width: 125%;
  display: flex;
  margin-left: 52px;
  align-items: center;
  position: fixed;
  background-color: white;
} */

.Navbar-head {
  margin-left: 60px;
  z-index: 3;
}

@media only screen and (max-width: 1500px) {
  .Navbar-head {
    margin-left: 50px;
  }
}

.dept {
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #e1e4e8;
  margin-top: 18px !important;
  margin-bottom: 15px;
  width: 150px;
  height: 32px;
  opacity: 1;
  font-size: 12px;
  background-color: #805382;
  font-family: Montserrat;
  color: white;
  padding-left: 5px;
  box-shadow: none !important;
  border-color:#805382 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 130px !important;
  padding-inline: 15px;
}


.qe-nv-drop-menu {
  margin-top: -12px !important;
  font-size: 13px;
  width: 130px !important;
  max-height: 125px !important;
  overflow-y: auto;
}

.dept-lab,
.proj-lab {
  font-size: 13px !important;
  margin-left: 2px !important;
  margin-bottom: 10px !important;
  position: absolute;
  color: #805382;
  font-weight: bold;
}

@media only screen and (max-width: 1500px) {
  .dpart-dropdown{
    width: 130px;
  }
}

/* .val {
  background-color: white;
  color: black;
} */



Navbar,
Container,
Navbar.Brand {
  width: 250px;
  /* font-size: 190px; */
  /* height: 35px; */
  /* margin-left: 20px; */
}

.navbar-brand {
  margin-left: 35px;
}

.head-image {
  width: 390px;
  height: 45px;
  margin-left: -20px;
}

.main_nav {
  height: 60px;
}

.dept:hover {
  /* background-color: #5d3b5e; */
  opacity: 1;
}

.user-text {
  margin-left: 20px;
  font-size: 15px;
}

.role-text {
  font-size: 13px;
  /* margin-left: auto; */
}

.p-name {
  color: #9f9f9f;
  font: normal normal normal 16px/19px Montserrat;
}

.image {
  width: 488px;
  height: 35px;
  margin-left: 20px;
}

.group {
  /* background-color: #805382; */
  border-radius: 5px;
  width: 180px;
  height: 32px;
  font-size: 14px;
  opacity: 1;
}

/* .dept:hover {
   background-color: #5d3b5e; 
} */

/* .user-name {
  font-size: 20px;
} */

.grey-text {
  color: #9f9f9f;
}

.search-box {
  height: 20px;
}

.avatar {
  margin-left: 42px;
  font-size: 16px;
  font: normal normal normal 16px/19px Montserrat;
  color: #9f9f9f;
  opacity: 1;
}

.profile {
  /* width: 580px; */
  gap: 10px;
  display: flex;
  justify-content: flex-end;
}

/* align-items: center;

  /* margin-left: 28px;
  cursor: pointer; */

input[type="search"] {
  margin-top: 0px;
  width: 400px;
  height: 35px;
  font-size: 14px;
  border: 2px solid#805382 !important;
  margin-right: 20px !important;
  box-shadow: none !important;
}

input[type="search"]:focus {
  border-color: #805382;
}

@media only screen and (max-width: 1500px) {
  input[type="search"] {
    width: 300px;
    height: 34px !important;
  }

  .head-image {
    width: 350px;
    height: 40px;
  }
  .dept {
    width:130px;
  }
  .qe-nv-drop-menu{
    width: 130px !important;
  }
}

@media only screen and (min-width: 1800px) {
  .head-image {
    width: 480px;
    height: 60px !important;
  }
}

input[type="search"]::placeholder {
  color: #9f9f9f;
  font-size: 14px;
  padding-left: 3px;
}

.search-box img {
  position: absolute;
}

.search-box {
  margin-right: 300px;
}

.search-icon {
  position: absolute !important;
  color: #805382;
  margin-top: 18px !important;
}

.search-box,
.head-search {
  /* text-align: left; */
  margin-right: 5px;
  margin-top: 12px !important;
  /* padding-right: 25px; */
}

.role-text {
  margin-left: 30px;
}

.profile-pic {
  margin-right: 10px;
  justify-content: flex-end;
}

.dropdown .dropdown-toggle {
  z-index: 1;
}


.btn-primary, .btn-primary:active, .btn-primary:visited{
  background-color: #805382 !important;
  border-color: #805382;
  }
  
  input[type="radio"] {
    accent-color: #805382;
  }
  
          

.qe-nv-drop-menu .dropdown-item:focus, .dropdown-item:hover {
    background-color: #805382 !important;
    color:white !important;
    border-radius:0px !important;
    border: none;
    outline: none;
  }

  /* .dpart-dropdown{
    margin-top: 18px;
    width: 150px;
    height: 32px;
    opacity: 1;
    font-size: 12px;
    background-color: #805382;
    font-family: Montserrat;
    color: white;
    padding-left: 5px;
    border-radius: 5px;
    cursor: pointer;
    border: none !important;
    box-shadow: none !important;
  } */

 /* .dept option .val{
  border-radius: 10px !important;
 } */

 /* .show>.btn-primary.dropdown-toggle{
  background-color:#805382;
  color:white;
  border-color: #805382;
 } */

 .btn-primary.dropdown-toggle:hover{
  background-color: #5D3B5E !important;
 }

 .dnv-Scroll {
  height: 130px;
  overflow-y: auto;
  font-size: 11px;
}   

.admin-top-green-navbar {
  background-color:#e9f5e4;
  width: 100%;
   top: 76px;
   left:50px;
  height: 70px;
  padding-right: 3%;
  z-index: 2;
  position:fixed;
  background-repeat: no-repeat,repeat;
}


.AD-navbar-brand {
  font: normal normal bold 16px/20px Montserrat;
  color: #7eb965 !important;
  opacity: 1;
  margin-left: 32px;
  height: 28px;
}

.dropdown_outline {
  background-color: white;
  border-radius: 10px;
  margin: 0 5px;
}

.dropdown_line {
  background: #7eb965 0% 0% no-repeat padding-box;
  color: white;
  margin: 0 5px;
  border-radius: 10px;
}


/* .s-option {
    height: 32px;
    width: 180px;
  } */

.s-option {
  margin-left: 15px;
  font-size: 12px;

}

.s-option option {
  background-color: white;
  font: normal normal medium 6px/10px Montserrat;
  color: #777777;
}

.admin-DHB-period{
  font-size: 13px !important;
  margin-left: 2px !important;
  margin-bottom: 20px !important;
  position: absolute;
  color: #7eb965;
  font-weight: bold;
}



.form-select-sm {
  margin-top: 18px;
}

.QE-s-option {
  box-shadow: none !important;
  border-style: none;
 }

 .adtg-nv-drop-menu{
  margin-top: 0px !important;
  font-size: 13px;
  width: 150px !important;
  max-height: 100px !important;
  overflow-y: scroll;
 }

 .adtg-nv-drop-menu .ad-pr-drop:hover{
  background-color: #7eb965 !important;
 }

 @media only screen and (max-width: 1500px) {
  .adtg-nv-drop-menu{
    width: 150px !important;
  }

  .AD-navbar-brand{
    margin-left: 25px !important;
    font-size: 16px;
  }
 }

 @media only screen and (min-width: 1900px){
  .AD-navbar-brand{
    font-size: 20px;
  }
 }

 .dept-admin {
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #e1e4e8 !important;
  margin-top: 18px !important;
  margin-bottom: 15px;
  width: 150px;
  height: 32px;
  opacity: 1;
  font-size: 12px;
  background-color: white !important;
  font-family: Montserrat;
  color: rgb(53, 51, 51) !important;
  padding-left: 5px;
  box-shadow: none !important;
}

.dept-admin:hover{
background-color: white !important;
border:  1px solid #e1e4e8 !important;
color: rgb(53, 51, 51)  !important;
}

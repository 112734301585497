@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

.slick-slider {
  width: 96% !important;
  height: 200px !important;
  /* background-color: red; */
  mix-blend-mode: normal !important;
  opacity: 1 !important;
  display: flex !important;
  margin-left: 42px !important;
  padding: 0% !important;
}

.slick-list {
  margin: 0 -5px;
}

.slick-slide>div {
  padding: 0 3px;
  margin: 0 3px;
}

.Summary-carousel {
  margin-left: 40px;
  /* z-index: -1; */
}

@media only screen and (max-width: 1500px) {
  .Summary-carousel {
    margin-left: 35px;
  }
}

.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: #805382 !important;
  /* box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%); */
}

.carousel .slick-prev {
  left: -20px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.carousel .slick-next {
  right: -20px;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.custom-indicator {
  bottom: -65px;
}

.custom-indicator li {
  width: 50px;
  height: 50px;
  filter: grayscale(100%);
}

.custom-indicator li.slick-active {
  filter: grayscale(0%);
}

/* .over-bi .bi-chevron-right {
  margin-left: 0px;
} */

.summary_header {
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin-left: 42px;
  padding-bottom: 10px;
  color: #805382;
  font: normal normal bold 18px/20px Montserrat;
}

.summary {
  margin-top: 5px;
  border-radius: 10px;
  /* width: 250px !important;
  height: 150px; */
  opacity: 1 !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #77777733;
  display: flex !important;
  font-size: 12px;
  justify-content: space-evenly;
  height:130px;
}

.summary_chart {
  width: 120px;
  height: 120px;
  /* margin-top: 7px; */
}

.summary_list ul {
  list-style-type: none;
  padding: 0%;
  margin-top: 10px;
}

.summary_list ul li {
  font: normal normal medium 13px/14px Montserrat;
  text-align: start;
}

.summary_list ul li:nth-child(even) {
  font-weight: bold;
  color: #555555;
}

.summary_list ul li:nth-child(odd) {
  color: #999999;
}

.summary_more {
  width: 100%;
  height: 134px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cecece 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal bold 18px/22px Montserrat;
  background: #fff;
  color: #805382;
}
.QE-top-green-navbar {
  background: #e9f5e4 0% 0% no-repeat padding-box;
  width: 100%;
  margin-top: 0px;
  margin-left: 50px;
  height: 70px;
  padding-right: 3%;
  z-index: 2;
  background-repeat: no-repeat,
    repeat;
}

@media only screen and (max-width: 1500px) {
  .QE-top-green-navbar {
    margin-left: 50px;
  }
  .QE-TG-ts .QE-s-option{
    width:150px !important;
  }
  
}

@media only screen and (max-width: 1400px) {
  .QE-navbar-brand {
    margin-left: 25px !important;
  }

  .dashboard-drop {
    margin-right: 27px !important;
  }
}

@media only screen and (min-width:1900px) {
  .QE-navbar-brand {
  font-size: 20px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .dashboard-drop {
    margin-right: 40px !important;
  }
}

.QE-navbar-brand {
  font: normal normal bold 16px/20px Montserrat;
  color: #7eb965 !important;
  opacity: 1;
  margin-left: 33px;
  height: 28px;
}

.dropdown_outline {
  background-color: white;
  border-radius: 10px;
  margin: 0 5px;
}

.dropdown_line {
  background: #7eb965 0% 0% no-repeat padding-box;
  color: white;
  margin: 0 5px;
  border-radius: 10px;
}

.dashboard-drop {
  margin-right: 25px;
}

.QE-s-option {
  margin-left: 10px;
  font-size: 12px;
  overflow-x: hidden;
}

.QE-s-option option {
  background-color: white;
  font: normal normal medium 6px/10px Montserrat;
  color: #777777;
}

@media only screen and (max-width: 1400px) {
  .dashboard-drop .form-select {
    width: 150px !important;
  }
}

.DHB-period{
  font-size: 13px !important;
  margin-left: 3px !important;
  margin-bottom: 20px !important;
  position: absolute;
  font-weight: bold;
  color: #7eb965;
}

.DHB-app {
  margin-left: 11px;
  font-size: 13px !important;
  margin-bottom: 20px !important;
  position: absolute;
  font-weight: bold;
  color: #7eb965;
}

.form-select-sm {
  margin-top: 18px;
}

.QE-s-option {
  box-shadow: none !important;
  border-style: none;
}


.tg-nv-drop-menu{
  margin-top: 0px !important;
  font-size: 13px;
  width: 150px !important;
  max-height: 100px !important;
  overflow-y: auto;
}

.tg-nv-drop-menu .dh-drop:hover, .nv-drop-menu .dh-drop:hover {
  background-color: #7eb965 !important;
}

.nv-drop-menu{
  margin-top: 0px !important;
  font-size: 13px;
  width: 150px !important;
  max-height: 100px !important;
  overflow-y: auto;
}

 @media only screen and (max-width: 1500px) {
  .nv-drop-menu{
    width: 150px !important;
    font-size: 13px;
  }

  .nv-drop-menu .dropdown-item{
    max-width: 150px !important;
  }

 }

 .QE-TG-ts .QE-s-option{
  border-color: none;
    border-style: none;
    border-radius: 4px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    background: #7eb965 !important;
    margin-top: 18px;
    width: 150px !important;
    align-content: center;
    height: 30px;
 }

 .nv-drop-menu .dropdown-item{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 180px !important;
  padding-inline: 15px;
 } 

 
 .QE-TG-ts .QE-s-option:hover{
  background: #5D874C !important;
  color: white;
 }

 .dashboard-drop .dept-admin:hover{
  background-color:#FFFFFF !important;
 }


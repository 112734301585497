.Canvas-sh-delete-head {
  background-color: #eeeeee;
  margin-top: 11.90rem;
  width: 33% !important;
}

.sh-delete-head {
  margin-top: 80px;
}

/*
.del-testsuite {
  margin-top: 184px;
  background-color: "#EEEEEE";
  margin-top: 184px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
*/

.del-sh-warn {
  width: 100px;
  align-items: center;
}

.sh-del-buton {
  border: none;
  background-color: #dc3545;
  border-radius: 5px;
  width: 128px;
  height: 40px;
  color: #fff;
}

.sh-can-buton {
  border: none;
  background-color: #9f9f9f;
  border-radius: 5px;
  width: 128px;
  height: 40px;
  color: #fff;
}

.del-sh-button {
  display: flex;
  gap: 14px;
  margin-top: 25px;
}

@media only screen and (max-width:1500px) {
  .sh-delete-head {
    font-size: 14px !important;
  }

  h1 {
    font-size: 35px !important;
  }

  .del-sh-button button {
    height: 30px !important;
    width: 110px !important;
  }
}
.edit-project-canvas{
  background-color: #f0e3f1;
  margin-top: 11.8rem !important;
  width: 40% !important;
  margin-left: 40px; 
  position: fixed;
}

@media all and (max-width: 1500px){
  .edit-project-canvas{
    width:35% !important;
  }
  .edit-project-button{
    margin-right: 35px !important;
  }

  .edit-pj-button{
    width: 100px !important;
    font: normal normal normal 13px/22px Montserrat !important;
  }

  .cancel-pj-edit{
    width: 100px !important;
    font: normal normal normal 13px/22px Montserrat !important;
  }

.edit-pj-label{
  font-size:13px !important;
  }
   /*  .ed-story-box{
    width: 405px !important; 
    margin-left: 30px !important;
  } */

  .edit-pj{
    font: normal normal bold 15px/19px Montserrat !important;
  }

  .ed-story-box{
    height: 33px !important;
    font-size: 13px !important;
  }
  
}


.edit-project-button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
  margin-top: 20px;
  gap: 10px;
}

.edit-pj-button {
  width: 120px;
  background-color: #7eb965;
  font: normal normal normal 15px/22px Montserrat;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
}

.edit-pj-button:hover {
  background-color: #5d874c;
}

.cancel-pj-edit{
  width: 120px;
  background-color: #999999;
  font: normal normal normal 15px/22px Montserrat;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
}

.cancel-pj-edit:hover {
  background-color: #5c5b5b;
}
.edit-pj {
  width: 100%;
  color: #805382;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 16px;
  font: normal normal bold 16px/19px Montserrat;
  opacity: 1;
}

.edit-pj-label{
  margin-left: 40px; 
  font-weight: bold;
 
}
.ed-story-box{
  border: 1px solid #9f9f9f;
  height: 38px;
  border-radius: 5px;
  width: 85%; 
  margin-left: 40px;
  font-size: 14px;
  /* padding: 0.375rem 0.75rem !important; */
}

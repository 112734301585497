.edit-testsuite {
  background-color: #f0e3f1;
  margin-top: 184px;
  margin-left: 40px;
  width: 50em;
  height: 100%;
}

.generate-input {
  width: 50px;
  height: 18px;
  padding: 7px;
  border-radius: 5px;
}

.generate-label {
  padding: 5px;
  font-size: 17px;
  color: #212529;
}

.generate-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
  margin-top: 20px;
  gap: 10px;
}

.gen-button {
  width: 130px;
  background-color: #7eb965;
  /* font: normal normal bold 18px/25px Montserrat; */
  color: white;
  padding: 5px;
  border: none;
  margin-left: 10px;
  border-radius: 5px;
}

.clear-button {
  width: 100px;
  border: none;
  /* font: normal normal bold 18px/25px Montserrat; */
  padding: 5px;
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  color: white;
  background-color: #999999;
}

.clear-button:hover {
  background-color: #5c5b5b !important;
}

.genInput {
  width: 550px;
}

@media screen and (max-width:1500px) {
  .genInput {
    height: 35px;
  }

  .gen-button,
  .clear-button{
    height: 35px !important;
    width: 110px !important;
  }
}

.gen-box {
  display: flex;

}

.save-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}


.gen-button:hover {
  background-color: #5d874c;
}

.story-box {
  border: 1px solid #9f9f9f;
  height: 38px;
  border-radius: 5px;
}

.gen-cancel {
  width: 130px;
  background-color: #999999;
  font: normal normal bold 18px/25px Montserrat;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.gen-cancel:hover {
  background-color: #5c5b5b !important;
}

.ts-gen {
  text-align: center;
  color: #805382;
  margin-left: 300px;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 16px;
  font: normal normal bold 16px/19px Montserrat;
  opacity: 1;
}

.gen-test-row {
  position: fixed;
  background-color: #e9f5e4;
  height: 64px;
  top: 76px;
  width: 100%;
  align-items: center;
  z-index: 2;
}

.gen-sec-head {
  color: #805382;
  font-weight: bold;
  margin-left: 75px;
  margin-top: 130px;
}

.gen-user-story-menus {
  margin-left: 75px;
}

@media screen and (max-width:1500px) {

  .gen-user-story-menus,
  .gen-sec-head {
    margin-left: 65px;
  }
}

@media all and (max-width:1500px) {
  .TC-crt-user-story-menus Label {
    font-size: 13.5px !important;
  }
}

@media screen and (max-width:1500px) {
  .TC-crt-user-story-menus select {
    height: 33px;
  }
}

@media screen and (max-width:1500px) {
  .gen-user-story-menus .gen-form {
    height: 33px !important;
    font-size: 13px !important;
  }
}

.gen-user-story-menus label ,
.genlabel{
  font-weight: bold !important;
}
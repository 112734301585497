/* .actions {
  display: flex;
  justify-items: end;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
} */

.testsuite-test-row {
  position: fixed;
  background-color: #e9f5e4;
  height: 70px;
  top: 76px;
  width: 100%;
  align-items: center;
}


.TS-action-icons {
  display: flex;
  margin-right: 20px;
  gap: 25px;
  align-items: center;
  justify-content: center;
}

.TSC-thead th{
  height:49px;
}

@media only screen and (max-width: 1500px) {
  .TS-action-icons {
    margin-left: 5px;
    gap: 18px;
    justify-content: center;
  }
}

.dropdown-menu {
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  min-width: 115px;
}

.dScroll, .dropdown-itemi{
  min-width: 150px;
}

/* .TS-APP-drop{
  display: flex;
  background-color: #7eb965;
  height: 20px;
} */

.testsuite-table {
  margin-top: 146px;
  padding-left: 32px;
  width: 100% !important;
  /* position: sticky;
   z-index: 1; */
  z-index: -10;
  position: absolute;
}

.testsuite-table tr th {
  text-align: center;
}
.testsuite-table th {
  /* margin-top: 50px; */
  z-index: -1;
  text-align: center;
  position: relative;
}

.testsuite-table tbody td {
  vertical-align: middle;
}

.qe_testsuite .dropdown-item:hover{
background-color: #7eb965 !important;
}

/* 
.dropdown-menu {
   position: absolute;
   z-index: 1;
   top: 100%;
   left: 0;
   } */
/* 
.dropdown-menu {
  position: absolute;
  z-index: 999;
  top: 100%;
  left: 0;
} */

.tc-found {
  display: flex;
  flex-direction: column;
  margin-top: 20%;
  margin-left: 45%;
}

 .TS-test-btns{
  margin-right: -5px;
 }

@media only screen and (max-width: 1500px) {
  .TS-test-btns {
    margin-right: 0px !important;
  }
  
  .TSC-thead th {
    font-size: 12.5px !important;
  }

  tbody td {
    font-size: 12px !important;
  }
}

.TSC-thead th {
  font-size: 14px;
  vertical-align: middle !important;
}

.TS-bulk,
.TS-App,
.TS-suite {
  font-size: 13px !important;
  color: #7eb965;
  font-weight: bold !important;
  margin-top: -2px;
}

.TS-bulk-btn {
  display: flex;
  align-items: center;
  border-color: none;
  border-style: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  background: #7eb965;
  margin-top: 18px;
  /* align-content: center; */
  height: 30px;
 /* overflow-x: hidden; */
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  /* max-width: 150px;
  min-width: 30px !important; */
  padding-inline: 15px;
  position: relative;
  /* padding-right: 30px; */
  gap: 5px;
  cursor: pointer;
}

.TS-app-btn{
  background-color: transparent;
  border: none;
  color: #fff;
  max-width: 100px;
  overflow-x: hidden;
}

.TS-bulk-btn:hover{
  background: #5D874C !important;
  color: white;
}

.TS-bulk-btn .dropdown-toggle{
  font-size: 1rem;
  position: relative;
}

.TS-bulk-btn .dropdown-menu{
  width: 145px !important;
  margin-left: -15px !important;
  margin-top: 5px !important;
}
/* 
.dropdown-menu .dropdown-item:hover {
    background-color: #7eb965;
    color:white !important;
    border-radius:0px !important;
  } */

.TS-create-btn {
  min-width: 170px;
  height: 30px;
  /* margin-right: 10px; */
  background-color: #7eb965;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: none;
  border-radius: 5px;
  margin-top: 15px !important;
}

.add-icon {
  margin-right: 3px;
  margin-bottom: 3px;
}

.test-suite-create-btn {
  /* margin-left: 5px; */
  width: 140px;
  height: 30px;
  margin-right: 20px;
  background: #7eb965;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 10px;
  border: none;
  border-radius: 5px;
  opacity: 1;
  margin-top: 17px;
}

.test-suite-create-btn:hover {
  background: #5D874C !important;
  color: white;
}

.test-suite-create-btn:focus {
    outline: 0 !important;
}

.testcase_not_found{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
}
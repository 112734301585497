.create-app-canvas{
  background-color: #f0e3f1;
  margin-top: 11.8rem !important;
  width: 40% !important;
  margin-left: 40px; 
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 40vh !important;
  }

  .CAPP-stake-select {
    background-color: #fff !important;
    width: 85% !important;
    margin-left: 40px !important;
    border: 1px solid grey;
    border-radius: 5px;
    border-collapse: collapse;
    height: 38px !important;
}
  
  @media all and (max-width:1500px){
    .create-app-canvas{
      width:35% !important;
    }

    .app-button {
      margin-right: 30px !important;
    }

    
  .create-app-button{
    width: 100px !important;
    font: normal normal normal 13px/22px Montserrat !important;
  }

  .cancel-app-button{
    width: 100px !important;
    font: normal normal normal 13px/22px Montserrat !important;
  }

  /* .app-story-box{
    width: 405px !important; 
    margin-left: 30px !important;
  }
*/

  .create-app{
      font: normal normal bold 15px/19px Montserrat !important;
    }
  }

  @media only screen and (max-width: 1500px){
    .create-app-label{
      font-size: 13px !important;
    } 

    .app-story-box{
      height: 33px !important;
      font-size: 13px !important;
    }
  }
  
  .app-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 50px;
    margin-top: 20px;
    gap: 10px;
    margin-bottom: 30px;
  }
  
  .create-app-button{
    background-color: #7eb965;
    width: 120px;
    font: normal normal normal 15px/22px Montserrat;
    color: white;
    padding: 5px;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
  }
  
.create-app-button:hover {
    background-color: #5d874c;
  }

.create-app-btn:hover{
  background-color: #5d874c;
}
  .app-story-box{
    border: 1px solid #9f9f9f;
    height: 38px;
    border-radius: 5px;
    width: 85%; 
    margin-left: 40px;
    font-size: 14px;
    
  }

  .app-desc-box{
     border: 1px solid #9f9f9f;
    height: 38px;
    border-radius: 5px;
    width: 85%; 
    margin-left: 40px;
    font-size: 14px;
    padding: 0.375rem 0.75rem !important;
  }

  .cancel-app-button{
    width: 120px;
    background-color: #999999;
    font: normal normal normal 15px/22px Montserrat;
    color: white;
    padding: 5px;
    border: none;
    border-radius: 5px;
  }
  
  .cancel-app-button:hover {
    background-color: #5c5b5b;
  }
  .create-app {
    width: 100%;
  color: #805382;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 16px;
  font: normal normal bold 16px/19px Montserrat;
  opacity: 1;
  }

  .create-app-label{
    margin-left: 40px; 
    font-weight: bold;
  
  }
  
  .create-app-btn{
    width: 150px;
    height: 30px;
    margin-right: 15px;
    background-color: #7eb965;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border: none;
    border-radius: 5px;
    font-weight: normal; 
  }


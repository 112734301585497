.testcase-edit {
  width: 100%;
}

.edit-TC {
  margin-top: 72px;
  z-index: 1;
}

.edit-loader {
  margin-top: 250px;
}

.edit-TC-editor {
  margin-top: 173px;
  color: #805382;
  font-weight: bold;
  margin-left: 75px;
}

@media only screen and (max-width:1500px) {
/* .ET-Editor-bottom-btns button{
  font-size: 13px !important;
  height: 30px !important;
  border-style: none;
} */
  .edit-TC-editor {
    margin-left: 65px;
  }
}

/* .edit-TC-opt p {
  font-size: 14px;
} */

.ET-Editor-bottom-btns button{
  font-size: 13px !important;
  height: 30px !important;
  border-style: none;
  cursor:pointer;
}

.title {
  margin-left: 112px;
  margin-top: 38px;
  font: normal normal bold 18px/22px Montserrat;
  color: "#805382";
}

.drop {
  display: flex;
  justify-content: center;
  margin-top: 0;
  gap: 20px;
  border: none;
  align-items: center;
  margin-left: auto;
}

.sec-head-para {
  margin-left: 25px;
  color: #805382;
  font-weight: 600;
}

.sec-editor-head {
  /* margin-left: 10px; */
  font-weight: 600;
  font-size: 14px;
  padding:0;
}

.mark-draft-btn {
  border: none !important;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  background: #7eb965;
  padding: 6px 25px;
}

.mark-draft-btn:hover {
  color: #fff;
  font-weight: bold;
  background: #5d874c;
}

/* Here starting the update options CSS*/

.TC-edit-updates {
 display: flex;
 margin-left: 50px;
 justify-content: space-around;
 /* margin-right: 105px !important; */
}

.edit-create-up,
.edit-up,
.last-up,
.status-up {
  font-size: 14px;
  width: 180px;
  color: #000;
}

.updates {
  color: #6b6a6a;
  font-weight: 500;
}

/* Here ending the update options CSS*/

.TC-edt-user-story-menus {
  margin-left: 27px;
  margin-top: 10px;
}

.TC-edt-user-story-menus ,
Form.Label {
  font-weight: bold;
  font-size: 15px;
}

@media only screen and (max-width: 1200px){
  .first-head, .sec-head{
    font-size: 15px !important;
  }
}

@media only screen and 
(min-width: 1900px){
.first-head{
  font-size: 20px;
}  
}

@media only screen and (max-width: 1500px) {
  .TC-edt-user-story-menus {
    margin-left: 18px;
  }

  /* .TC-edit-updates {
    margin-right: 95px !important;
  } */
}

@media only screen and (max-width:1900px ){
    .TC-edit-updates {
    margin-right: 0px !important;
  }
}

.TC-edt-user-story-menus Form.Select {
  margin-left: 25px;
  border: 1px solid #9F9F9F;
}

/*Editor design*/

.edt-editor-head {
  color: #333333;
  font-weight: bold;
  padding: 0;
  margin-top: 7px;
  /* margin-left: 25px; */
}

.edt-react-editors {
  font-family: Montserrat;
  font-size: 14px;
  border: 1px #9f9f9f solid;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  /* height: 73.5vh; */
}

@media all and (max-width:1500px) {
  /* .edt-react-editors {
    height: 72.3vh;
  } */

  .edt-editor-head {
    font-size: 14px;
  }
}

/*Form section*/

@media all and (max-width:1500px) {
  .TC-edt-user-story-menus Label {
    font-size: 13.5px !important;
  }
}

@media screen and (max-width:1500px) {
  .TC-edt-user-story-menus select {
    height: 33px;
  }
}

@media screen and (max-width:1500px) {
  .TC-edt-user-story-menus .edt-form {
    height: 33px !important;
    font-size: 13px !important;
  }
}

/* 
  .input {
    margin-left: 112px;
    margin-top: 48px;
    width: 560px;
    height: 38px;
  } */
.menu {
  width: 560px;
  height: 38px;
}

.btn {
  /* margin-left: 365px; */
  border: 1px #9f9f9f solid;
}

.testcase-editscreen {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  width: 1168px;
}

.top-info {
  display: flex;
  gap: 50px;
}

.editinfo-buttons {
  display: flex;
  margin-left: 100px;
  margin-right: 40px;
}

.editscreen-buttons {
  display: flex;
  margin-left: 50px;
  gap: 20px;
}

.ts-buttons {
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 148px;
}

.left-input {
  width: 600px;
}

.editor-button {
  display: flex;
  /* gap: 50px; */
}

.edit-screen-buttons {
  display: flex;
  margin-left: 10px;
  width: 1168px;
  gap: 20px;
}

.update-editor {
  font-family: Montserrat;
  margin-top: 40px;
  font-size: 14px;
  /* height: 648px; */
  border: 1px #9f9f9f solid;
  border-radius: 5px;
  font-size: 14px;
  background: none;
  background-color: #9f9f9f;
}

.put-editor {
  font-family: Montserrat;
  font-size: 14px;
  height: 648px;
  border: 1px #9f9f9f solid;
  border-radius: 5px;
  font-size: 14px;
  background: none;
}

/* .revise-header {
  margin-top: 400px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: left;
} */

/* .ts-revision {
  margin-right: 20px;
} */

.revision-icon {
  display: flex;
  justify-content: flex-end;
  margin-top: -15px;
}

.revise-tbl {
  border-radius: 5px;
  border: 1px solid #9f9f9f;
  margin-left: 25px;
  margin-top: 40px;
}

.revise-tbl td {
  font-weight: 600;
}

Table {
  border-collapse: separate;
  border-spacing: 0;
}

.edit-create-test-btn {
  width: 150px;
  height: 30px;
  margin-right: 10px;
  background-color: #7eb965;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 10px;
  border: none;
  border-radius: 5px;
  opacity: 1;
}

.edit-create-test-btn:hover {
  background: #65a24a;
  color: white;
}

.edit-clone, .editor-code{
  height: 95%;
}

.revise-tbl td{
padding-top: 15px !important;
}

/* .edt-react-editors pre{
  display: none !important;
}

.edt-react-editors textarea{
 -webkit-text-fill-color: rgb(43, 42, 42) !important;

} */


/* .ET-Editor-bottom-btns button{
  border:none !important;
  } */

  /* @media all and (max-width:1500px){
.cltest-run-button{
margin-right: 20px;
}
} */

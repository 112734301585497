.Canvas-delete-head {
  background-color: #eeeeee;
  margin-top: 12.2rem;
  width: 33% !important;
}

.delete-head {
  margin-top: 80px;
}

.del-testsuite {
  margin-top: 184px;
  background-color: "#EEEEEE";
  margin-top: 184px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.del-tc-warn {
  width: 100px;
  align-items: center;
}

.del-buton {
  border: none;
  background-color: #dc3545;
  border-radius: 5px;
  width: 128px;
  height: 40px;
  color: #fff;
}

.del-buton:hover{
  background-color: #C82333;
}

.can-buton {
  border: none;
  background-color: #9f9f9f;
  border-radius: 5px;
  width: 128px;
  height: 40px;
  color: #fff;
}

.can-buton:hover{
  background-color: #5c5b5b;
}

.del-tc-button {
  display: flex;
  gap: 14px;
  margin-top: 25px;
}

.del-tc-button button{
  cursor:pointer;
}

@media only screen and (max-width:1500px) {
  .delete-head {
    font-size: 14px !important;
  }

  h1 {
    font-size: 35px !important;
  }

  .del-tc-button button {
    height: 30px !important;
    width: 110px !important;
  }
}